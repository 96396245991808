import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// Create a theme instance.
let theme = createTheme({
  palette: {
    // mode: 'dark',
    // mode: 'light',
    primary: {
      main: '#00767e',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#f50057',
      mainTransparent: '#f5005722',
    },
    success: {
      main: '#198754',
    },

    primaryBlackText: {
      main: '#00b5ad',
      contrastText: '#000000',
    },
    white: {
      main: "#ffffff",
      contrastText: '#000000',
    },
    black: {
      main: "#000000",
      contrastText: '#ffffff',
    },
    lightGray: {
      main: "#ededed",
      contrastText: '#000000',
    },
    middleGrey: {
      main: "#CCCACA",
      contrastText: '#000000',
    },
    grey: {
      main: "#222222",
      contrastText: '#ced0d4',
    },
    textWhite: {
      main: '#ffffff',
    },
    text: {
      main: '#000000',
    },
    textBlue: {
      main: "#2e475d"
    },
    error: {
      main: red.A400,
    },
    
    apply: {
      fg: "#FFFFFF",
      bg: "#21855B",
      bgHover: "#1C734F",
    },
    delete: {
      fg: "#FFFFFF",
      bg: "#E05252",
      bgHover: "#DA2F2F",
    },
    information: {
      fg: "#FFFFFF",
      bg: "#05668D",
      bgHover: "#044B67",
    },
    hidden:{
      fg: "#FF3333",
      bg: "#E0E0E0"
    },

    job: {
      bg: '#ffffff',
      bgHover: '#caf0f8',
      border: '#252422',
    }
  },
  typography: {
    fontFamily: [
      "Lexend Deca", 
      "Lato",
      "Roboto",
      "Open Sans",
      "sans-serif"
    ].join(','),
    button: {
      textTransform: 'none'
    },
  },
  custom: {
    formShadow: 3
  },
});

theme = responsiveFontSizes(theme);

export default theme;
