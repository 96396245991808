// Next
import * as React from 'react';
import PropTypes from 'prop-types';
import Head from 'next/head';

// MUI
import { CacheProvider } from '@emotion/react';
import createEmotionCache from '../src/createEmotionCache';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

// Local
import '../styles/global.css'
import theme from '../src/theme';
import AppContext from "../src/AppContext";

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

export default function App(props) {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const [user, setUser] = React.useState(null);

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>

      <ThemeProvider theme={theme}>

        <AppContext.Provider value={{
          user: user,
          setUser: setUser
        }}>

          <CssBaseline />
          <Component {...pageProps} />

        </AppContext.Provider>

      </ThemeProvider>
    </CacheProvider>
  );
}

App.propTypes = {
  Component: PropTypes.elementType.isRequired,
  emotionCache: PropTypes.object,
  pageProps: PropTypes.object.isRequired,
};
